<template>
  <div>
    <span>this is mine page!</span>
  </div>
</template>

<script>
export default {
name: "Index"
}
</script>

<style scoped>

</style>